import { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import SearchPage from '../components/page/search/SearchPage';
import { prepareResponse } from './api/[[...slug]]';
import getAppData from '../connectors/newport/appData/getAppData';
import * as Sentry from '@sentry/nextjs';

// Page types
const DynamicCategory = dynamic(() => import('../components/page/category'));
const DynamicCMS = dynamic(() => import('../components/page/cms'));
const DynamicProduct = dynamic(() => import('../components/page/product'));
const DynamicError = dynamic(() => import('../components/page/404'));

function Page(props) {
    const router = useRouter();
    const [ state, setState ] = useState(props);
    const [ loading, setLoading ] = useState(false);
    let { pageData, appData } = state;

    useEffect(() => {
        const handleStart = (url) => {
            console.log(`Loading: ${url}`);
            setLoading(true);
        };

        const handleStop = () => {
            console.log('loading done');
            setLoading(false);
        };

        window.addEventListener('trigger-page-refresh', triggerPageRefresh);
        router.events.on('routeChangeStart', handleStart);
        router.events.on('routeChangeComplete', handleStop);
        router.events.on('routeChangeError', handleStop);

        return () => {
            window.removeEventListener('trigger-page-refresh', triggerPageRefresh);
            router.events.off('routeChangeStart', handleStart);
            router.events.off('routeChangeComplete', handleStop);
            router.events.off('routeChangeError', handleStop);
        };
    }, [ router ]);

    useEffect(() => {
        if (!loading) {
            let event = document.createEvent('HTMLEvents');
            event.initEvent('pageLoaded', true, true);
            event.eventName = 'pageLoaded';
            window.dispatchEvent(event);
        }
    }, [ router.asPath, loading ]);

    useEffect(() => {
        setState(props);
    }, [ props ]);

    async function triggerPageRefresh() {
        window.location.href = router.asPath;
    }

    function renderPageType(page) {
        if (!page?.hasOwnProperty('type') && loading) {
            return '';
        }

        switch (page?.type) {
            case 'error':
            case null:
            case undefined:
                return <DynamicError {...page} statusCode={500} isLoading={loading} />;
            case 'category':
                return <DynamicCategory {...page} appData={appData} isLoading={loading} />;
            case 'product':
                return <DynamicProduct {...page} appData={appData} isLoading={loading} />;
            case 'cms':
                return <DynamicCMS {...page} appData={appData} isLoading={loading} />;
            case 'search':
                return <SearchPage />;
            case 'no-route':
            default:
                let statusCode = 404;
                return <DynamicError {...page} statusCode={statusCode} isLoading={loading} />;
        }
    }

    return (
        <>
            {renderPageType(pageData)}
        </>
    );
}

async function getPossibleRedirect(url, referer, salesChannelId) {
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), 2000);

    const payload = {
        salesChannelId,
        url,
        referer,
    };

    let hasRedirect = await fetch('https://my.newport.se/api/fourofour', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
        signal: controller.signal,
    });

    console.log('redirectStatus', hasRedirect.status, hasRedirect.headers.get('content-type'));

    clearTimeout(id);

    return hasRedirect;
}

export async function getServerSideProps(context) {
    const appDataCache = async (context) => {
        let data = null;
        if (context?.req?.cacheManager) {
            const cacheManager = context.req.cacheManager;
            const cacheKey = `app-data:${context.req.currentDomainConfig.salesChannelId}:${context?.req?.buildId}`;
            data = await cacheManager.get(cacheKey);
            if (!data) {
                data = await getAppData(context.req);
                if (data) {
                    cacheManager.set(cacheKey, JSON.stringify(data), 120).catch((res) => {
                        console.error('app-data-cache-failed', cacheKey);
                    });
                    console.log('app-data-set', cacheKey);
                }
            } else {
                data = JSON.parse(data);
                console.log('app-data-recover', cacheKey);
            }
        }
        return data;
    };

    try {
        console.log('resolved',
            context?.resolvedUrl,
            context?.params,
            context?.query,
        );

        const resolver = await Promise.all([
            await appDataCache(context),
            await prepareResponse(
                context.req,
                context.res,
                context?.params?.slug || context?.slug || [],
                context.query,
            ),
        ]);

        const appData = resolver[0];
        const pageResponse = resolver[1];
        const statusCode = pageResponse.statusCode;

        const props = {
            appData,
            ...pageResponse,
            statusCode,
        };

        console.log('HTTP Status', context?.resolvedUrl, statusCode);

        if (statusCode === 404) {
            try {
                const basePath = context.req.currentDomainConfig.basePath.replace(/\/$/, '');
                const url = `${basePath}${context.resolvedUrl}`;

                const redirect = await getPossibleRedirect(
                    url,
                    context.req.header('host'),
                    context.req.currentDomainConfig.salesChannelId,
                );

                if (redirect.status < 400 && redirect.headers.get('content-type') === 'application/json') {
                    const redirectJson = await redirect.json();

                    if (
                        redirectJson.result === 'REDIRECT'
                        && redirectJson.destination
                        && (context.resolvedUrl !== redirectJson.destination)
                    ) {
                        context.res.writeHead(redirectJson.redirect_type, {
                            'Location': redirectJson.destination,
                            'Content-Type': 'text/html; charset=utf-8',
                        });

                        context.res.statusCode = 301;
                        context.res.end();
                    }
                    console.log('redirectJson', redirectJson, url);
                } else {
                    console.log('RedirectBodyError', await redirect.text());
                }

            } catch (e) {
                console.error('RedirectError', e);
            }

            console.log('404-resolver', context.resolvedUrl);
        }

        context.res.statusCode = statusCode;
        if ((statusCode => 200 && statusCode <= 299) && pageResponse?.pageData?.cacheable) {
            context.res.setHeader(
                'Cache-Control',
                'public, max-age=600, s-maxage=300, stale-while-revalidate=900, stale-if-error=3600',
            );

            if (props?.cacheSurrogateKeys?.length) {
                context.res.setHeader('Surrogate-Key', props.cacheSurrogateKeys.join(' '));
            }
        }

        return {
            props: {
                ...props,
                namespacesRequired: [ 'general', 'attributes', 'errors', 'product-info' ],
            },
        };
    } catch (e) {
        console.error('Error Detected', e);
        context.res.statusCode = 500;

        Sentry.captureException(e, 'All fail safes failed in slug.');

        let appData = null;
        try {
            appData = await appDataCache(context);
        } catch (e) {
        }

        return {
            props: {
                statusCode: 500,
                status: 'error',
                type: 'error',
                error: 'Server Error',
                appData: appData,
            },
        };
    }
}

export default Page;
